<div class="modal-header">
  <h4 class="modal-title">Demander de nouveaux codes</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="form">
    <div class="form-group row">
      <label for="countInput" class="col-sm-4 col-form-label text-right">Nombre de codes *</label>
      <div class="col-sm-8">
        <input type="number" class="form-control" id="countInput" formControlName='count' min="1">
      </div>
    </div>
    <div class="form-group row">
      <label for="examCenterSelect" class="col-sm-4 col-form-label text-right">Centre d'examen *</label>
      <div class="col-sm-8">
        <select class="form-control" formControlName="examCenterId" id="examCenterSelect">
          <option *ngFor="let examCenter of examCenters" [value]='examCenter.id'>
            {{ examCenter.nom }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="sessionSelect" class="col-sm-4 col-form-label text-right">Session</label>
      <div class="col-sm-8">
        <select *ngIf="sessions != null" class="form-control" formControlName="sessionId" id="sessionSelect">
          <option value="">Aucune</option>

          <option *ngFor="let session of sessions" [value]='session.id'>
            {{ session.code }}
          </option>
        </select>

        <span *ngIf="sessions == null" class="text-secondary">
          Veuillez sélectionner un centre
        </span>

        <span *ngIf="sessions != null && sessions.length == 0" class="text-secondary">
          Aucune session à venir pour ce centre
        </span>
      </div>
    </div>
    <div class="form-group row">
      <label for="commentInput" class="col-sm-4 col-form-label text-right">Commentaire</label>
      <div class="col-sm-8">
        <textarea class="form-control" formControlName="comment" id="commentInput">
        </textarea>
      </div>
    </div>
  </form>
  <div *ngIf="error" class="alert alert-danger">
    {{error}}
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()"
    [disabled]="submitting">Annuler</button>
  <button type="button" class="btn btn-outline-success" [disabled]="form.invalid || submitting" (click)="submit()">
    <span *ngIf="submitting" class="button-loader"></span>
    Valider
  </button>
</div>