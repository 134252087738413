import {Component, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {take} from "rxjs/operators";
import {ExamCenterService} from "../../../shared/services/exam-center/exam-center.service";
import {ExamCenter} from "../../../shared/models/examCenter";
import {FormGroup, FormControl, Validators} from "@angular/forms";

@Component({
    selector: "app-request-exams-modal",
    templateUrl: "./request-exams-modal.component.html",
    styleUrls: ["./request-exams-modal.component.scss"]
})
export class RequestExamsModalComponent {
    private _examCenters: ExamCenter[] = [];

    @Input()
    set examCenters(value: ExamCenter[]) {
    this._examCenters = value;

    // On ne fait la configuration du formulaire qu'une fois qu'on a la donnée
    if (this._examCenters && this._examCenters.length) {
        this.initFormWithExamCenters(this._examCenters);
    }
    }

    get examCenters(): ExamCenter[] {
    return this._examCenters;
    }

    error: string;
    submitting: boolean = false;
    form: FormGroup;

    constructor (
        public activeModal: NgbActiveModal,
        public examCenterService: ExamCenterService
    ) {
        this.form = new FormGroup({
            count: new FormControl(
                1,
                [Validators.required]
            ),
            examCenterId: new FormControl(
                null,
                [Validators.required]
            ),
            sessionId: new FormControl(
                "",
            ),
            comment: new FormControl("")
        });
    }

    initFormWithExamCenters(examCenters: ExamCenter[]) {
        // Exemples d'initialisation/patch
        if (examCenters.length === 1) {
          const singleCenter = examCenters[0];
          this.form.patchValue({
            examCenterId: singleCenter.id,
            sessionId: singleCenter.exam_sessions.length === 1 
              ? singleCenter.exam_sessions[0].id 
              : ""
          });
        } else {
          // Plus d'un examCenter => on met des valeurs vides par exemple
          this.form.patchValue({ examCenterId: "", sessionId: "" });
        }
      }
      

    submit () {
        this.submitting = true;

        const { count, examCenterId, comment, sessionId } = this.form.value;

        this.examCenterService.requestExams(
            examCenterId,
            sessionId,
            count,
            comment,
        ).pipe(take(1)).
            subscribe(
                (res) => {
                    this.activeModal.close(res);
                },
                (err) => {
                    this.error = "Une erreur est survenue lors l'envoi de la demande des codes";
                    this.submitting = false;
                    throw err;
                }
            );
    }

    get sessions () {
        if (!this.form.value.examCenterId) {
            return null;
        }

        const selectedExamCenter = this.examCenters.find(
            (examCenter) => examCenter.id == this.form.value.examCenterId
        );

        if (!selectedExamCenter) {
            return null;
        } else {
            return selectedExamCenter.exam_sessions;
        }
    }

}
