<div style="display: flex; justify-content: center;">
    <div style="max-width: 600px; padding: 40px 0;">

        <h1 style="text-align: center; margin-bottom: 30px;">
            Création d'un compte
        </h1>

        <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
            <div class="card" style="margin-bottom: 20px;">
                <div class="card-header">
                    <span class="float-left">Identifiants</span>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="form-group col">
                            <label for="inputEmail">Email</label>
                            <input type="email" class="form-control" formControlName="email" id="inputEmail">
                            <small class="text-danger" *ngIf="errors['email']">{{errors['email']}}</small>
                            <ng-container
                                *ngIf="f['email'].invalid && (f['email'].dirty || f['email'].touched || submitted)">
                                <small *ngIf="f['email'].errors.required" style="color: red;">
                                    L'email est requis
                                </small>
                                <small *ngIf="f['email'].errors.email" style="color: red;">
                                    L'email est invalide
                                </small>
                            </ng-container>
                        </div>

                        <div class="form-group col">
                            <label for="inputPhone">Numéro de téléphone</label>
                            <input type="tel" class="form-control" formControlName="phone_number" id="inputPhone"
                                appPhoneValidator>
                            <small class="text-danger" *ngIf="errors['phone_number']">{{errors['phone_number']}}</small>
                            <ng-container
                                *ngIf="f['phone_number'].invalid && (f['phone_number'].dirty || f['phone_number'].touched || submitted)">
                                <small *ngIf="f['phone_number'].errors.required" style="color: red;">
                                    Le numéro de téléphone est requis
                                </small>
                                <small *ngIf="f['phone_number'].errors?.invalidFormat" style="color: red;">Le numéro de
                                    téléphone est invalide</small>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col">
                            <label for="inputPassword1">Mot de passe</label>
                            <input type="password" class="form-control" formControlName="password1" id="inputPassword1">
                            <small class="text-danger" *ngIf="errors['password1']">{{errors['password1']}}</small>
                            <ng-container
                                *ngIf="f['password1'].invalid && (f['password1'].dirty || f['password1'].touched || submitted)">
                                <small *ngIf="f['password1'].errors.required" style="color: red;">
                                    Le mot de passe est requis
                                </small>
                            </ng-container>
                        </div>

                        <div class="form-group col">
                            <label for="inputPassword2">Confirmez votre mot de passe</label>
                            <input type="password" class="form-control" formControlName="password2" id="inputPassword2">
                            <small class="text-danger" *ngIf="errors['password2']">{{errors['password2']}}</small>
                            <small
                                *ngIf="f['password1'].value && f['password2'].value && f['password1'].value !== f['password2'].value"
                                style="color: red;">
                                Les mots de passe ne correspondent pas
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card" style="margin-bottom: 20px;">
                <div class="card-header">
                    <span class="float-left">Etat civil</span>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="form-group col">
                            <label for="inputLastName">Nom</label>
                            <input type="text" class="form-control" formControlName="last_name" id="inputLastName">
                            <ng-container
                                *ngIf="f['last_name'].invalid && (f['last_name'].dirty || f['last_name'].touched || submitted)">
                                <small *ngIf="f['last_name'].errors.required" style="color: red;">
                                    Le nom est requis
                                </small>
                            </ng-container>
                        </div>

                        <div class="form-group col">
                            <label for="inputFirstName">Prénom</label>
                            <input type="text" class="form-control" formControlName="first_name" id="inputFirstName">
                            <ng-container
                                *ngIf="f['first_name'].invalid && (f['first_name'].dirty || f['first_name'].touched || submitted)">
                                <small *ngIf="f['first_name'].errors.required" style="color: red;">
                                    Le prénom est requis
                                </small>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col">
                            <label for="inputFirstName2">Deuxième prénom (Facultatif)</label>
                            <input type="text" class="form-control" formControlName="first_name_2" id="inputFirstName2">
                            <ng-container
                                *ngIf="f['first_name_2'].invalid && (f['first_name_2'].dirty || f['first_name_2'].touched || submitted)">
                                <small *ngIf="f['first_name_2'].errors.required" style="color: red;">
                                    Le deuxième prénom est requis
                                </small>
                            </ng-container>
                        </div>

                        <div class="form-group col">
                            <label for="inputFirstName3">Troisième prénom (Facultatif)</label>
                            <input type="text" class="form-control" formControlName="first_name_3" id="inputFirstName3">
                            <ng-container
                                *ngIf="f['first_name_3'].invalid && (f['first_name_3'].dirty || f['first_name_3'].touched || submitted)">
                                <small *ngIf="f['first_name_3'].errors.required" style="color: red;">
                                    Le troisième prénom est requis
                                </small>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col">
                            <label for="inputSex">Sexe</label>
                            <select class="form-control" formControlName="sex" id="inputSex">
                                <option value="M">
                                    Homme
                                </option>
                                <option value="F">
                                    Femme
                                </option>
                            </select>
                            <ng-container *ngIf="f['sex'].invalid && (f['sex'].dirty || f['sex'].touched || submitted)">
                                <small *ngIf="f['sex'].errors.required" style="color: red;">
                                    Le sexe est requis
                                </small>
                            </ng-container>
                        </div>

                        <div class="col"></div>
                    </div>
                </div>
            </div>

            <div class="card" style="margin-bottom: 20px;">
                <div class="card-header">
                    <span class="float-left">Naissance</span>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="form-group col">
                            <label for="dateInput">Date de naissance</label>
                            <div class="input-group">
                                <input class="form-control" id="dateInput" placeholder="jj/mm/aaaa"
                                    formControlName='birthday_date' name="dp" ngbDatepicker #d="ngbDatepicker"
                                    [minDate]="{year: 1920, month: 1, day: 1}">
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary calendar" (click)="d.toggle()"
                                        type="button">
                                        <i class="far fa-calendar-alt"></i>
                                    </button>
                                </div>
                            </div>

                            <ng-container
                                *ngIf="f['birthday_date'].invalid && (f['birthday_date'].dirty || f['birthday_date'].touched || submitted)">
                                <small *ngIf="f['birthday_date'].errors.required" style="color: red;">
                                    La date de naissance est requis
                                </small>
                            </ng-container>
                        </div>

                        <div class="col"></div>
                    </div>

                    <div class="row">
                        <div class="form-group col">
                            <label for="inputBirthZipcode">Code postal de la ville de naissance</label>
                            <input type="text" class="form-control" formControlName="birth_zipcode"
                                id="inputBirthZipcode">
                            <ng-container
                                *ngIf="f['birth_zipcode'].invalid && (f['birth_zipcode'].dirty || f['birth_zipcode'].touched || submitted)">
                                <small *ngIf="f['birth_zipcode'].errors.required" style="color: red;">
                                    Le code postal de la ville de naissance est requis
                                </small>
                                <small *ngIf="f['birth_zipcode'].errors.maxlength" style="color: red;">
                                    Le code postal ne peut pas contenir plus de 6 chiffres
                                </small>
                            </ng-container>
                        </div>

                        <div class="form-group col">
                            <label for="inputBirthCity">Ville de naissance</label>
                            <input type="text" class="form-control" formControlName="birth_city" id="inputBirthCity">
                            <ng-container
                                *ngIf="f['birth_city'].invalid && (f['birth_city'].dirty || f['birth_city'].touched || submitted)">
                                <small *ngIf="f['birth_city'].errors.required" style="color: red;">
                                    Le code postal de la ville de naissance est requis
                                </small>
                            </ng-container>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col">
                            <label for="inputBirthCountry">Pays de naissance</label>
                            <app-country-select formControlName="birth_country"
                                id="inputBirthCountry"></app-country-select>
                            <ng-container
                                *ngIf="f['birth_country'].invalid && (f['birth_country'].dirty || f['birth_country'].touched || submitted)">
                                <small *ngIf="f['birth_country'].errors.required" style="color: red;">
                                    Le pays de naissance est requis
                                </small>
                            </ng-container>
                        </div>

                        <div class="col"></div>
                    </div>
                </div>
            </div>

            <div class="card" style="margin-bottom: 20px;">
                <div class="card-header">
                    <span class="float-left">Coordonnées</span>
                </div>

                <div class="card-body">
                    <div class="form-group">
                        <label for="inputAddress">Adresse</label>
                        <input type="text" class="form-control" formControlName="address" id="inputAddress">
                        <ng-container
                            *ngIf="f['address'].invalid && (f['address'].dirty || f['address'].touched || submitted)">
                            <small *ngIf="f['address'].errors.required" style="color: red;">
                                L'adresse est requise
                            </small>
                        </ng-container>
                    </div>

                    <div class="row">
                        <div class="form-group col">
                            <label for="inputZipcode">Code postal</label>
                            <input type="text" class="form-control" formControlName="zipcode" id="inputZipcode">
                            <ng-container
                                *ngIf="f['zipcode'].invalid && (f['zipcode'].dirty || f['zipcode'].touched || submitted)">
                                <small *ngIf="f['zipcode'].errors.required" style="color: red;">
                                    Le code postal est requis
                                </small>
                                <small *ngIf="f['zipcode'].errors.maxlength" style="color: red;">
                                    Le code postal ne peut pas contenir plus de 6 chiffres
                                </small>
                            </ng-container>
                        </div>

                        <div class="form-group col">
                            <label for="inputCity">Ville</label>
                            <input type="text" class="form-control" formControlName="city" id="inputCity">
                            <ng-container
                                *ngIf="f['city'].invalid && (f['city'].dirty || f['city'].touched || submitted)">
                                <small *ngIf="f['city'].errors.required" style="color: red;">
                                    La ville est requise
                                </small>
                            </ng-container>
                        </div>
                    </div>

                </div>
            </div>

            <div class="card" style="margin-bottom: 20px;">
                <div class="card-header">
                    <span class="float-left">Handicape</span>
                </div>

                <div class="card-body">
                    <div class="form-group">
                        <div style="display: flex; gap: 10px; align-items: center;">
                            <div style="width: 30px;">
                                <input type="checkbox" class="form-control" formControlName="psh" />
                            </div>
                            <label class="form-label">
                                Je suis en situation de handicape
                            </label>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="inputPshComment">Décrivez votre handicape</label>
                        <textarea type="text" class="form-control" formControlName="psh_comment"
                            id="inputPshComment"></textarea>
                    </div>
                </div>
            </div>

            <div class="card" style="margin-bottom: 20px;">
                <div class="card-header">
                    <span class="float-left">Mon Compte Formation (Facultatif)</span>
                </div>

                <div class="card-body">
                    <div class="form-group">
                        <label for="inputFileNumber">Référence dossier CPF</label>
                        <input type="text" class="form-control" formControlName="file_number" id="inputFileNumber">
                        <small class="text-danger" *ngIf="errors['file_number']">{{errors['file_number']}}</small>
                    </div>
                </div>
            </div>

            <p>
                En tant qu'organisme certificateur agréé par l'état, nous recueillons vos coordonnées et état civil aux
                termes des articles L. 6113-8 et R. 6113-17-1 du code du travail. Ces informations nous permettent :
            </p>

            <ul>
                <li>
                    D'enregistrer les résultats de vos examens et de vous les adresser directement.
                </li>
                <li>
                    De communiquer les informations relatives aux titulaires des certifications délivrées au système
                    d'information du compte personnel de formation prévu au II de l'article L. 6323-8, selon les
                    modalités de mise en œuvre fixées par décret en Conseil d'Etat.
                </li>
            </ul>

            <p>
                Vos informations personnelles sont sécurisées et conservées dans le cadre de notre rôle d'organisme
                certificateur agréé. Aucune information n'est transmise ou revendue à un tiers à d'autre fins, notamment
                commerciales.
            </p>

            <div class="form-group">
                <div class="row" style="align-items: center;">
                    <input type="checkbox" class="col-1 form-control" formControlName="accepted" />
                    <label class="col col-form-label">
                        J'ai compris et accepte l'utilisation de mes données personnelles dans le cadre du processus de
                        traitement lié aux examens enregistrés aux répertoires nationaux de France Competences.
                    </label>
                </div>

                <small class="text-danger" *ngIf="errors['accepted']">{{errors['accepted']}}</small>

                <ng-container
                    *ngIf="f['accepted'].invalid && (f['accepted'].dirty || f['accepted'].touched || submitted)">
                    <small *ngIf="f['accepted'].errors.required" style="color: red;">
                        Vous devez cocher cette case pour continuer
                    </small>
                </ng-container>
            </div>

            <div class="form-group">
                <div class="row" style="align-items: center;">
                    <input type="checkbox" class="col-1 form-control" formControlName="confirmed" />

                    <label class="col col-form-label">
                        Je confirme l'exactitude des données renseignées ci-dessus.
                    </label>
                </div>

                <small class="text-danger" *ngIf="errors['confirmed']">{{errors['confirmed']}}</small>

                <ng-container
                    *ngIf="f['confirmed'].invalid && (f['confirmed'].dirty || f['confirmed'].touched || submitted)">
                    <small *ngIf="f['confirmed'].errors.required" style="color: red;">
                        Vous devez cocher cette case pour continuer
                    </small>
                </ng-container>
            </div>

            <div class="form-group">
                <div class="row" style="align-items: center;">
                    <input type="checkbox" class="col-1 form-control" formControlName="submitted" />

                    <label class="col col-form-label">
                        J'accepte l'utilisation de mon adresse e-mail dans le cadre de la communication et l'utilisation
                        de la plateforme de certification media-cert.fr
                    </label>
                </div>

                <small class="text-danger" *ngIf="errors['submitted']">{{errors['submitted']}}</small>

                <ng-container
                    *ngIf="f['submitted'].invalid && (f['submitted'].dirty || f['submitted'].touched || submitted)">
                    <small *ngIf="f['submitted'].errors.required" style="color: red;">
                        Vous devez cocher cette case pour continuer
                    </small>
                </ng-container>
            </div>

            <div class="form-group">
                <label for="inputCode" style="font-size: 1.5rem; font-weight: bold;">Code d'acces a l'examen</label>

                <input type="text" class="form-control" formControlName="code" id="inputCode">

                <small class="text-danger" *ngIf="errors['code']">{{errors['code']}}</small>

                <ng-container *ngIf="f['code'].invalid && (f['code'].dirty || f['code'].touched || submitted)">
                    <small *ngIf="f['code'].errors.required" style="color: red;">
                        Le code est requis
                    </small>
                </ng-container>
            </div>

            <div class="alert alert-danger" *ngIf="errors['default']">
                {{errors['default']}}
            </div>

            <button [disabled]="!canSubmit" type="submit" class="btn btn-primary btn-block">
                S'enregistrer
            </button>
        </form>
    </div>
</div>