import {Component, OnInit, Input, OnDestroy} from "@angular/core";
import {User} from "../../../shared/models/user";
import {FormGroup, FormControl} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {UserService} from "../../../shared/services/user/user.service";
import {ExamCenterService} from "../../../shared/services/exam-center/exam-center.service";
import {take} from "rxjs/operators";
import {SupervisorProfile} from "../../../shared/models/supervisorProfile";
import {ExamCenter} from "../../../shared/models/examCenter";
import {GroupsEnum} from "../../../shared/enums/groupsEnum";
import {Group} from "../../../shared/models/group";
import {GroupService} from "../../../shared/services/group/group.service";
import {Subject, forkJoin, Observable} from "rxjs";
import {StudentProfile} from "../../../shared/models/studentProfile";
import {Exam} from "../../../shared/models/exam";

@Component({
    selector: "app-user-modal",
    templateUrl: "./user-modal.component.html",
    styleUrls: ["./user-modal.component.scss"]
})
export class UserModalComponent implements OnInit, OnDestroy {

    @Input() inputUser: User;
    @Input() defaultGroup: GroupsEnum;

    GroupsEnum: GroupsEnum;
    userForm: FormGroup;
    centers: Array<ExamCenter>;
    groups: Array<Group>;
    isDataLoading: boolean = true;
    error: string;
    unsubscribe = new Subject<void>();
    isStudentForm = false;
    user: User;
    submitting: boolean = false;

    constructor (
        public activeModal: NgbActiveModal,
        public examCenterService: ExamCenterService,
        public groupService: GroupService,
        private userService: UserService
    ) {
        this.userForm = new FormGroup({
            firstname: new FormControl(""),
            firstname2: new FormControl(""),
            firstname3: new FormControl(""),
            lastname: new FormControl(""),
            email: new FormControl(""),
            phoneNumber: new FormControl(""),
            isSupervisor: new FormControl(""),
            center: new FormControl(""),
            isCorrector: new FormControl(""),
            birthdayDate: new FormControl(""),
            sex: new FormControl(null),
            birthZipcode: new FormControl(""),
            birthCity: new FormControl(""),
            birthCountry: new FormControl(""),
            address: new FormControl(""),
            zipcode: new FormControl(""),
            city: new FormControl(""),
            wantsToBePublished: new FormControl(false),
            voucherCode: new FormControl(""),
            psh: new FormControl(false),
            pshComment: new FormControl(""),
        });
        this.isDataLoading = true;
    }

    ngOnInit () {

        // If there's a default group, or if the user is a student, we'll show the student's form
        this.isStudentForm = this.defaultGroup == GroupsEnum.STUDENT || this.inputUser?.isStudent;


        // Fetch ids of the groups in the db
        const getGroups = this.groupService.list();

        const getUser = this.userService.getMe();

        const promises: Array<Observable<any>> = [
            getGroups,
            getUser
        ];

        // Fetch list of exam centers from the db
        const getExamCenters = this.examCenterService.list();
        if (!this.isStudentForm) {
            promises.push(getExamCenters);
        }

        // Fill user form once all data has been fetched
        forkJoin(promises).pipe(take(1)).
            subscribe(([
                groups,
                user,
                centers
            ]) => {
                this.groups = groups;
                this.user = user;
                if (centers) {
                    this.centers = centers;
                }
                this.resetForm();
                this.isDataLoading = false;
            });
    }

    // On destroy clear the form subscription
    ngOnDestroy (): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    /*
     * Function to instanciate the frontend form
     * Call only after all data is fetched
     */
    private resetForm () {
        if (this.inputUser) {

            this.userForm.patchValue({
                firstname: this.inputUser.first_name,
                lastname: this.inputUser.last_name,
                email: this.inputUser.email,
                phoneNumber: this.inputUser.phone_number,
                isSupervisor: this.inputUser.isSupervisor,
                center: this.inputUser.supervisor_profile?.center?.id
                    ? this.inputUser.supervisor_profile.center.id
                    : null,
                isCorrector: this.inputUser.isCorrector,
                birthdayDate: this.inputUser.student_profile?.birthday_date
                    ? new Date(this.inputUser.student_profile.birthday_date)
                    : null,
                sex: this.inputUser.student_profile ? this.inputUser.student_profile.sex : null,
                birthZipcode: this.inputUser.student_profile
                    ? this.inputUser.student_profile.birth_zipcode
                    : null,
                birthCity: this.inputUser.student_profile
                    ? this.inputUser.student_profile.birth_city
                    : null,
                birthCountry: this.inputUser.student_profile
                    ? this.inputUser.student_profile.birth_country
                    : null,
                address: this.inputUser.student_profile
                    ? this.inputUser.student_profile.address
                    : null,
                zipcode: this.inputUser.student_profile
                    ? this.inputUser.student_profile.zipcode
                    : null,
                city: this.inputUser.student_profile
                    ? this.inputUser.student_profile.city
                    : null,
                wantsToBePublished: this.inputUser.student_profile
                    ? this.inputUser.student_profile.wants_to_be_published
                    : false,
                firstname2: this.inputUser.student_profile ? this.inputUser.student_profile.first_name_2 : null,
                firstname3: this.inputUser.student_profile ? this.inputUser.student_profile.first_name_3 : null,
                // The voucher code is a field only available when creating a new user anyways
                voucherCode: "",
                psh: this.inputUser.student_profile ? this.inputUser.student_profile.psh : false,
                pshComment: this.inputUser.student_profile ? this.inputUser.student_profile.psh_comment : "",
            });

        } else if (this.defaultGroup) {
            this.userForm.get("isSupervisor").setValue(this.defaultGroup == GroupsEnum.SUPERVISOR);
            this.userForm.get("isCorrector").setValue(this.defaultGroup == GroupsEnum.CORRECTOR);

        }


        // If the user is an exam center, set the exam center of the form to be the user's exam center
        if (this.user.isExamCenterBoss) {
            this.userForm.controls.center.setValue(this.user.examCenters[0].id);
            this.userForm.get("center").disable();
        }
    }

    getGroupFromEnum (groupEnum: GroupsEnum): Group {
        return this.groups.find((g: Group) => g.name == groupEnum);
    }

    validate () {
        this.submitting = true;

        const submitUser = new User(this.inputUser);
        submitUser.first_name = this.userForm.value.firstname;
        submitUser.last_name = this.userForm.value.lastname;
        submitUser.email = this.userForm.value.email;
        submitUser.phone_number = this.userForm.value.phoneNumber;
        submitUser.groups = [];

        if (this.isStudentForm) {

            const formattedBirthDate = this.userForm.value.birthdayDate ? this.userForm.value.birthdayDate.getFullYear() + '-' + ('0' + (this.userForm.value.birthdayDate.getMonth() + 1)).slice(-2) + '-' + ('0' + this.userForm.value.birthdayDate.getDate()).slice(-2) : null;

            submitUser.student_profile = new StudentProfile(submitUser.student_profile);
            submitUser.student_profile.birthday_date = formattedBirthDate;
            submitUser.student_profile.sex = this.userForm.value.sex;
            submitUser.student_profile.birth_zipcode = this.userForm.value.birthZipcode;
            submitUser.student_profile.birth_city = this.userForm.value.birthCity;
            submitUser.student_profile.birth_country = this.userForm.value.birthCountry;
            submitUser.student_profile.address = this.userForm.value.address;
            submitUser.student_profile.zipcode = this.userForm.value.zipcode;
            submitUser.student_profile.city = this.userForm.value.city;
            submitUser.student_profile.wants_to_be_published = this.userForm.value.wantsToBePublished;
            submitUser.student_profile.first_name_2 = this.userForm.value.firstname2 ? this.userForm.value.firstname2 : null;
            submitUser.student_profile.first_name_3 = this.userForm.value.firstname3 ? this.userForm.value.firstname3 : null;
            submitUser.student_profile.psh = this.userForm.value.psh;
            submitUser.student_profile.psh_comment = this.userForm.value.pshComment;
            submitUser.groups.push(this.getGroupFromEnum(GroupsEnum.STUDENT));

            // Setting up an exam for a user is only available when creating the user, not when updating
            if (!this.inputUser) {
                submitUser.exams = [];
                if (this.userForm.controls.voucher_code.value) {
                    submitUser.exams.push(new Exam({voucher_code: this.userForm.controls.voucher_code.value}));
                }

                // If the current user is supervisor or center boss, he HAS to specify a voucher code
                else if (this.user.isSupervisor || this.user.isExamCenterBoss) {
                    this.error = "Renseignez le code de l'examen auquel associer ce candidat";
                    this.submitting = false;
                    return;
                }
            }


        } else {

            if (this.userForm.value.isSupervisor) {
                submitUser.supervisor_profile = new SupervisorProfile(submitUser.supervisor_profile);
                submitUser.supervisor_profile.center = this.centers.find((center: ExamCenter) => center.id == this.userForm.controls.center.value);
                submitUser.groups.push(this.getGroupFromEnum(GroupsEnum.SUPERVISOR));
            }
            if (this.userForm.value.isCorrector) {
                submitUser.groups.push(this.getGroupFromEnum(GroupsEnum.CORRECTOR));
            }


        }


        // Trigger update if it's an edit
        if (this.inputUser) {
            this.userService.update(submitUser).pipe(take(1)).
                subscribe(
                    (res: User) => {
                        this.inputUser = res;
                        this.resetForm();
                        this.activeModal.close(this.inputUser);
                    },
                    (err) => {
                        this.error = "Une erreur est survenue lors de la modification de l'utilisateur";
                        throw err;
                    },
                    () => {
                        this.submitting = false;
                    }
                );
        } else {
            this.userService.create(submitUser).pipe(take(1)).
                subscribe(
                    (res: User) => {
                        this.inputUser = res;
                        this.resetForm();
                        this.activeModal.close(this.inputUser);
                    },
                    (err) => {
                        this.error = "Une erreur est survenue lors de la création de l'utilisateur";
                        throw err;
                    },
                    () => {
                        this.submitting = false;
                    }
                );
        }
    }
}
