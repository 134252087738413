import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {Exam} from "../../models/exam";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";
import {Invoice} from "../../models/invoice";
import {ExamResultFile} from "../../models/examResultFile";
import {ExamFilterEnum} from "../../enums/examFilterEnum";
import {ExamCenter} from "../../models/examCenter";
import { ExamSession } from "../../models/examSession";

@Injectable({
    providedIn: "root"
})
export class ExamService {

    constructor (private http: HttpClient) { }

    list (page?: number, term?: string, filters?: Array<ExamFilterEnum>, sessionId?: number): Observable<Array<Exam>> {
        let params = new HttpParams();
        
        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }

        if (page) {
            params = params.set(
                "page",
                `${page}`
            );
        }

        if (filters) {
            params = params.set(
                "filters",
                filters.join(",")
            );
        }

        if (sessionId) {
            params = params.set(
                "session",
                `${sessionId}`
            );
        }

        return this.http.get<Array<Exam>>(
            `${environment.baseApiUrl}/exams/`,
            {params}
        ).
            pipe(map((res) => res.map((d) => new Exam(d))));
    }

    retrieve (id: number | string) {
        return this.http.get<Array<Exam>>(`${environment.baseApiUrl}/exams/${id}/`).
            pipe(map((res) => new Exam(res)));
    }

    count (term: string, filters?: Array<ExamFilterEnum>): Observable<number> {
        let params = new HttpParams();

        if (term) {
            params = params.set(
                "term",
                `${term}`
            );
        }

        if (filters) {
            params = params.set(
                "filters",
                filters.join(",")
            );
        }
        
        return this.http.get<number>(
            `${environment.baseApiUrl}/exams/count/`,
            {params}
        );
    }

    update (exam: Exam) {
        return this.http.put<Exam>(
            `${environment.baseApiUrl}/exams/${exam.id}/`,
            exam
        ).pipe(map((res) => new Exam(res)));
    }

    partialUpdate (id: number | string, data) {
        return this.http.patch<Exam>(
            `${environment.baseApiUrl}/exams/${id}/`,
            data
        ).pipe(map((res) => new Exam(res)));
    }

    generate (count: number, invoice: Invoice, examCenter: ExamCenter): Observable<Array<Exam>> {
        const params = {
            count,
            exam_center: examCenter
        } as any;
        if (invoice) {
            params.invoice = invoice;
        }
        return this.http.post<Array<Exam>>(
            `${environment.baseApiUrl}/exams/generate/`,
            params
        ).pipe(map((res) => res.map((d) => new Exam(d))));
    }

    getExamResultFiles (id: number): Observable<Array<ExamResultFile>> {
        return this.http.get<Array<ExamResultFile>>(`${environment.baseApiUrl}/exams/${id}/exam_result_files/`).pipe(map((res) => res.map((d) => new ExamResultFile(d))));
    }

    uploadResultFile (examId: number, document: File, category: number): Observable<any> {
        const formData = new FormData();
        formData.append(
            "file",
            document
        );
        formData.append(
            "category",
            `${category}`
        );
        return this.http.put(
            `${environment.baseApiUrl}/exams/${examId}/upload_result_file/`,
            formData,
            {reportProgress: true, observe: "events"}
        );
    }

    uploadDiplome (examId: number, document: File): Observable<Exam> {
        const formData = new FormData();
        formData.append(
            "file",
            document
        );
        return this.http.put<string>(
            `${environment.baseApiUrl}/exams/${examId}/upload_diplome/`,
            formData
        ).pipe(map((res) => new Exam(res)));
    }

    toggleCanPass (id: number) {
        return this.http.post<Exam>(
            `${environment.baseApiUrl}/exams/${id}/toggle_can_pass/`,
            {}
        ).pipe(map((res) => new Exam(res)));
    }

    startTest (id: number, testId: number, type: "text" | "blob"): Observable<string | Blob> {
        const params = new HttpParams().set(
            "test_id",
            `${testId}`
        );

        if (type == "blob") {
            return this.http.get(
                `${environment.baseApiUrl}/exams/${id}/start_test/`,
                {
                    responseType: "blob",
                    params
                }
            );

        }

        return this.http.get(
            `${environment.baseApiUrl}/exams/${id}/start_test/`,
            {
                responseType: "text",
                params
            }
        );
    }

    startMockTest (id: number, testId: number, type: "text" | "blob"): Observable<string | Blob> {
        const params = new HttpParams().set(
            "test_id",
            `${testId}`
        );

        if (type == "blob") {
            return this.http.get(
                `${environment.baseApiUrl}/exams/${id}/start_mock_test/`,
                {
                    responseType: "blob",
                    params
                }
            );

        }

        return this.http.get(
            `${environment.baseApiUrl}/exams/${id}/start_mock_test/`,
            {
                responseType: "text",
                params
            }
        );
    }

    reset (id: number) {
        return this.http.post<Exam>(
            `${environment.baseApiUrl}/exams/${id}/reset/`,
            {}
        ).pipe(map((res) => new Exam(res)));
    }

    comment (id: number, comment: string) {
        return this.http.post<Exam>(
            `${environment.baseApiUrl}/exams/${id}/comment/`,
            {comment}
        ).pipe(map((res) => new Exam(res)));
    }

    certificatePdf (id: number): Observable<HttpResponse<Blob>> {
        return this.http.get(
            `${environment.baseApiUrl}/exams/${id}/certificate_pdf/`,
            {
                responseType: "blob",
                observe: "response" // Observe the full response
            }
        );
    }

    confirmStudentAttendance (id: number): Observable<Exam> {
        return this.http.post<Exam>(
            `${environment.baseApiUrl}/exams/${id}/confirm_student_attendance/`,
            {}
        ).pipe(map((res) => new Exam(res)));
    }


}
