<div class="modal-header">
    <h4 *ngIf="!inputUser && isStudentForm" class="modal-title">Ajouter un candidat</h4>
    <h4 *ngIf="!inputUser && !isStudentForm" class="modal-title">Ajouter un utilisateur</h4>
    <h4 *ngIf="inputUser" class="modal-title">Utilisateur {{inputUser.fullName}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="userForm" *ngIf="!isDataLoading">
        <div class="form-group row">
            <label for="firstNameInput" class="col-sm-4 col-form-label text-right">Prénom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="firstNameInput" formControlName='firstname'>
            </div>
        </div>
        <div class="form-group row">
            <label for="firstName2Input" class="col-sm-4 col-form-label text-right">Deuxième prénom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="firstName2Input" formControlName='firstname2'>
            </div>
        </div>
        <div class="form-group row">
            <label for="firstName3Input" class="col-sm-4 col-form-label text-right">Troisième prénom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="firstName3Input" formControlName='firstname3'>
            </div>
        </div>
        <div class="form-group row">
            <label for="lastNameInput" class="col-sm-4 col-form-label text-right">Nom</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="lastNameInput" formControlName='lastname'>
            </div>
        </div>
        <div class="form-group row">
            <label for="emailInput" class="col-sm-4 col-form-label text-right">Mail</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="emailInput" formControlName='email'>
            </div>
        </div>
        <div class="form-group row">
            <label for="phoneNumberInput" class="col-sm-4 col-form-label text-right">Téléphone</label>
            <div class="col-sm-8">
                <input type="text" class="form-control" id="phoneNumberInput" formControlName='phoneNumber'>
            </div>
        </div>
        <ng-container *ngIf="!isStudentForm">
            <div class="form-group row" [hidden]="user.isExamCenterBoss">
                <div class="col-sm-8 offset-sm-4">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="isSupervisorInput"
                            formControlName='isSupervisor'>
                        <label for="isSupervisorInput" class="form-check-label">Superviseur</label>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="userForm.value.isSupervisor">
                <label for="centerInput" class="col-sm-4 col-form-label text-right">Centre d'examen</label>
                <div class="col-sm-8">
                    <select class="form-control" formControlName="center" id="centerSelect">
                        <option *ngFor="let boss of centers; let i = index" [value]='centers[i].id'>
                            {{centers[i].nom}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row" [hidden]="user.isExamCenterBoss">
                <div class="col-sm-8 offset-sm-4">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="isCorrectorInput"
                            formControlName='isCorrector'>
                        <label for="isCorrectorInput" class="form-check-label">Correcteur</label>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isStudentForm">
            <div class="form-group row">
                <label for="sex-select" class="col-sm-4 col-form-label text-right">Sexe</label>
                <div class="col-sm-8">
                    <select class="form-control" formControlName="sex" id="sex-select">
                        <option selected disabled [value]="null">
                            Sélectionnez
                        </option>
                        <option value='M'>
                            Homme
                        </option>
                        <option value='F'>
                            Femme
                        </option>
                    </select>
                </div>
            </div>
            <div class="form-group row">
                <label for="birthdayDateInput" class="col-sm-4 col-form-label text-right">Date de naissance</label>
                <div class="input-group col-sm-8">
                    <input class="form-control" id="birthdayDateInput" placeholder="jj/mm/aaaa"
                        formControlName='birthdayDate' name="dp" ngbDatepicker #d="ngbDatepicker"
                        [minDate]="{year: 1920, month: 1, day: 1}">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i
                                class="far fa-calendar-alt"></i></button>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="birthZipcodeInput" class="col-sm-4 col-form-label text-right">Code postal de
                    naissance</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="birthZipcodeInput" formControlName='birthZipcode'>
                </div>
            </div>
            <div class="form-group row">
                <label for="birthCityInput" class="col-sm-4 col-form-label text-right">Ville de naissance</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="birthCityInput" formControlName='birthCity'>
                </div>
            </div>
            <div class="form-group row">
                <label for="birthCountryInput" class="col-sm-4 col-form-label text-right">Pays de naissance</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="birthCountryInput" formControlName='birthCountry'>
                </div>
            </div>
            <div class="form-group row">
                <label for="addressInput" class="col-sm-4 col-form-label text-right">Adresse</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="addressInput" formControlName='address'>
                </div>
            </div>
            <div class="form-group row">
                <label for="zipcodeInput" class="col-sm-4 col-form-label text-right">Code postal</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="zipcodeInput" formControlName='zipcode'>
                </div>
            </div>
            <div class="form-group row">
                <label for="cityInput" class="col-sm-4 col-form-label text-right">Ville</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="cityInput" formControlName='city'>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-8 offset-sm-4">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="pshInput" formControlName='psh'>
                        <label for="pshInput" class="form-check-label">Personne en situation de handicape</label>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label for="pshCommentInput" class="col-sm-4 col-form-label text-right">Description du handicape</label>
                <div class="col-sm-8">
                    <textarea class="form-control" id="pshCommentInput" formControlName='pshComment'></textarea>
                </div>
            </div>
            <div class="form-group row">
                <div class="col-sm-8 offset-sm-4">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input" id="wantsToBePublishedInput"
                            formControlName='wantsToBePublished'>
                        <label for="wantsToBePublishedInput" class="form-check-label">Publier dans le registre des
                            professionnels certifiés</label>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="!inputUser">
                <label for="voucherCodeInput" class="col-sm-4 col-form-label text-right">Code examen</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" id="voucherCodeInput" formControlName='voucherCode'>
                </div>
            </div>
        </ng-container>
    </form>
    <div *ngIf="error" class="alert alert-danger">
        {{error}}
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="activeModal.dismiss()"
        [disabled]="submitting">Annuler</button>
    <button type="button" class="btn btn-outline-success" (click)="validate()" [disabled]="submitting">
        <span *ngIf="submitting" class="button-loader"></span>
        Valider
    </button>
</div>